type StrIsh = { toString: () => string };

/**
 * Compares two objects that implement the `toString` method for equality, ignoring case.
 *
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 * @returns {boolean} Returns `true` if the string representations of both objects are equal.
 */
export function iEqual(a: StrIsh, b: StrIsh): boolean {
  return a.toString().toLowerCase() === b.toString().toLowerCase();
}

import { INovaEntity } from './base';

export enum AssetContainerType {
  Trailer = 'Trailer',
  Container = 'Container',
  Oversized = 'Oversized',
  Other = 'Other'
}

export interface IAssetContainer extends INovaEntity {
  orgId: string;
  warehouseId: string;
  type: AssetContainerType;
  code: string;
  assetVisitId?: string | null;
  notes?: string | null;
}
